<template>
  <div class="overflow-auto relative" :style="{ width: componentWidth, height: componentHeight }">
    <div
      class="flex items-center gap-2 text-sm text-gray-500 font-semibold my-1 rounded-lg sticky top-0 bg-gray-50"
      style="z-index: 1;"
      :style="{ width: innerComponentWidth + 'px' }"
    >
      <div
        class="w-72 h-[38px] px-3 flex-shrink-0 sticky left-0 top-0 bg-gray-50 bg-opacity-80 flex items-center"
        style="z-index: 1;"
      >
        Pessoa
      </div>
      <div>
        <div ref="innerComponent" class="flex gap-2">
          <div
            v-for="(day, index) in tableDays"
            :key="'y' + index"
            class="w-8 text-center items-center justify-center flex flex-col py-1 px-0.5 rounded-lg bg-gray-50 bg-opacity-50"
          >
            <span>{{ day | formatLocaleDateString({ day: 'numeric' }) }}</span>
            <span class="text-xs opacity-70">{{ day | formatLocaleDateString({ month: 'short' }) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div :style="{ width: innerComponentWidth + 'px' }">
      <div
        v-for="item in userMaps"
        :key="item.key"
        class="flex hover:bg-gray-200 hover:bg-opacity-50 h-14 rounded-lg gap-2"
      >
        <a :href="openMapPath(item)" class="w-72 flex-shrink-0 sticky left-0 bg-gray-50 bg-opacity-80 z-20">
          <Person size="xs" class="w-72" :person="users[item.user_key]" />
        </a>
        <div class="flex gap-2">
          <div
            v-for="(day, indexj) in tableDays"
            :key="'x' + indexj"
            class="w-8 text-center flex items-center justify-center"
          >
            <fw-icon-checkbox-circle-solid v-if="day in (item.days ?? {})" class="w-5 h-5 text-primary" />
            <fw-icon-checkbox-circle v-else class="w-5 h-5 text-gray-300 bg-white rounded-full bg-opacity-50" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import _ from 'lodash'

export default {
  name: 'PanelStickyTable',
  components: {
    Person
  },
  props: {
    year: {
      type: Number,
      required: true
    },
    startDate: {
      type: String,
      default: null
    },
    endDate: {
      type: String,
      default: null
    },
    userMaps: {
      type: Array,
      default: () => []
    },
    users: {
      type: Object,
      default: () => {}
    },
    tableParentRefName: {
      type: String
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    noVerticalScroll: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tableDays: [],
      //tableMonths: [],
      innerComponentWidth: 500,
      componentHeight: '100px',
      componentWidth: '100px'
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language ?? 'pt'
    }
  },

  watch: {
    startDate() {
      this.generateDates()
    },

    endDate() {
      this.generateDates()
    }
  },

  mounted() {
    this.calculateSize()
    this.generateDates()
    //add listener to window resize
    window.addEventListener('resize', _.debounce(this.calculateSize, 1000))
  },

  beforeDestroy() {
    //remove listener to window resize
    window.removeEventListener('resize', _.debounce(this.calculateSize, 1000))
  },

  methods: {
    calculateSize() {
      this.$nextTick(() => {
        let height = '100%'
        let width = '100%'
        if (this.noVerticalScroll) {
          height = 'auto'
        } else {
          if (this.height) {
            height = this.height
          } else if (this.tableParentRefName && this.$parent.$refs?.[this.tableParentRefName]) {
            height = this.$parent.$refs?.[this.tableParentRefName]?.clientHeight + 'px'
          }
        }
        if (this.width) {
          width = this.width
        } else if (this.tableParentRefName && this.$parent.$refs?.[this.tableParentRefName]) {
          width = this.$parent.$refs?.[this.tableParentRefName]?.clientWidth + 'px'
        }
        this.componentHeight = height
        this.componentWidth = width
      })
    },

    formatDate(date) {
      //DD/MM/YYYY to YYYY-MM-DD
      return date
        .split('/')
        .reverse()
        .join('-')
    },

    openMapPath(item) {
      return this.$router.resolve({
        name: 'manage-map-user',
        params: {
          year: item.year,
          userKey: item.user_key,
          mapUserKey: item.key,
          key: item.map_key
        }
      }).href
    },

    generateDates() {
      console.log('startDate', this.startDate)
      console.log('endDate', this.endDate)
      let start = this.startDate ? this.formatDate(this.startDate) : `${this.year}-01-01`
      let end = this.endDate ? this.formatDate(this.endDate) : `${this.year}-12-31`
      this.tableDays = this.dateRange(start, end).days.map(el => Dates.formatDateToAPI(el))
      this.$nextTick(() => {
        console.log('innerComponent', this.$refs['innerComponent'])
        this.innerComponentWidth = this.$refs['innerComponent'].offsetWidth
      })
    },

    dateRange(startDate, endDate, steps = 1) {
      const dateArray = []
      const dateByMonth = {}
      let currentDate = new Date(startDate)

      while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate))

        if (currentDate.getUTCMonth() in dateByMonth) {
          // dateByMonth[currentDate.getUTCMonth()].push(new Date(currentDate))
          dateByMonth[currentDate.getUTCMonth()] += 1
        } else {
          // dateByMonth[currentDate.getUTCMonth()] = [new Date(currentDate)]
          dateByMonth[currentDate.getUTCMonth()] = 1
        }
        // Use UTC date to prevent problems with time zones and DST
        currentDate.setUTCDate(currentDate.getUTCDate() + steps)
      }

      return { days: dateArray, months: dateByMonth }
    }
  }
}
</script>

<style lang="postcss">
table.table-sticky {
  white-space: nowrap;
  table-layout: fixed;
}

/* Set header to be sticky */
table.table-sticky thead tr:first-child th {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 20vw;
}
table.table-sticky thead tr:first-child th:first-child {
  z-index: 2;
}

table.table-sticky thead tr th {
  position: sticky;
  top: 28px;
  z-index: 1;
  width: 25vw;
}

table.table-sticky td {
}

table.table-sticky tbody th {
  position: relative;
}
/* Set left column to be sticky */
table.table-sticky thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
/* Set left column to be sticky */
table.table-sticky tbody th {
  position: sticky;
  left: 0;
  z-index: 1;
}
</style>
