<template>
  <div ref="parent" class="pt-5 overflow-x-auto no-scrollbar">
    <div ref="yearmap" class="relative w-full" :style="{ height: mapHeight + 'px' }"></div>

    <div v-if="distinctIcons?.length" class="hidden">
      <component :is="'fw-icon-' + icon" v-for="(icon, index) in distinctIcons" :id="icon" :key="index"></component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockYearActivity',
  props: {
    maxValue: {
      type: Number,
      default: 10
    },
    year: {
      type: Number,
      default: () => new Date().getFullYear()
    },
    /**
     * Ex:
     * { '01/01/2024': 1, '02/01/2024': 2, '05/01/2024': 4 }
     */
    data: {
      type: Object,
      default: () => {}
    },
    /**
     * Ex:
     * { '25/12/2024': 'text-yellow-500' }
     */
    customClass: {
      type: Object,
      default: () => {}
    },
    customIcons: {
      type: Object,
      default: () => {}
    },
    christmasTree: {
      type: Boolean,
      default: true
    },
    showToday: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: false
    },
    expand: {
      type: String,
      default: 'auto' //auto or horizontal
    },
    weekendColor: {
      type: String,
      default: 'bg-gray-300'
    }
  },

  data() {
    return {
      mapHeight: 100
    }
  },

  computed: {
    distinctIcons() {
      return this.customIcons ? Array.from(new Set(Object.values(this.customIcons))) : []
    }
  },

  watch: {
    data() {
      this.$refs.yearmap.innerHTML = ''
      this.generateMap()
    }
  },

  mounted() {
    this.generateMap()
  },

  methods: {
    generateMap() {
      //get available space
      let width = this.$refs.parent.offsetWidth
      let minWidth = 750
      console.debug('Available width', width)
      let colWidth = 12
      let lineHeigth = 12
      let gapSize = 1.5
      if (this.expandable && width > minWidth) {
        //expand the map
        let ratio = width / minWidth
        colWidth = colWidth * ratio
        if (this.expand == 'auto') {
          lineHeigth = lineHeigth * ratio
        }
        gapSize = gapSize * ratio
      }

      this.mapHeight = lineHeigth * 7 + gapSize * 6 + 20
      let leftMargin = 20

      //loop every day of the year
      let col = 0
      let row = 0
      let currentMonth = 0
      let monthsLabels = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      let weekDays = ['2ª', '3ª', '4ª', '5ª', '6ª', 'S', 'D']

      //generate week day labels
      for (let w = 0; w <= weekDays.length; w++) {
        if (weekDays[w]) {
          let weeklabel = document.createElement('div')
          weeklabel.classList.add('absolute')
          weeklabel.classList.add('text-xs')
          weeklabel.classList.add('text-gray-400')
          weeklabel.classList.add('flex')
          weeklabel.classList.add('flex-col')
          weeklabel.classList.add('justify-center')
          weeklabel.classList.add('items-center')
          weeklabel.style.top = row * lineHeigth + gapSize * row - 0.5 + 'px'
          weeklabel.style.left = '0px'
          weeklabel.style.height = lineHeigth + 2 + 'px'
          weeklabel.innerHTML = weekDays[w]
          this.$refs.yearmap.appendChild(weeklabel)
        }
        row++
      }

      //Move the start of the year to the first day of the week
      row = new Date(this.year, 0, 1).getDay()
      //start on monday
      row--
      if (row < 0) row = 6
      //move to the first day of the week
      for (let j = 1; j <= 12; j++) {
        for (let i = 1; i <= 31; i++) {
          let day = i < 10 ? '0' + i : i
          let month = j < 10 ? '0' + j : j
          let date = day + '/' + month + '/' + this.year
          //check if day is valid
          if (this.isDateValid(date)) {
            if (currentMonth != j) {
              console.debug('create month label', j, monthsLabels[j - 1])
              //add month label
              if (monthsLabels[j - 1]) {
                let monthLabel = document.createElement('div')
                monthLabel.classList.add('absolute')
                monthLabel.classList.add('h-4')
                monthLabel.classList.add('text-xs')
                monthLabel.classList.add('text-gray-500')
                monthLabel.style.left = col * colWidth + gapSize * col + 1 + leftMargin + 'px'
                monthLabel.style.top = '-16px'
                monthLabel.innerHTML = monthsLabels[j - 1]
                this.$refs.yearmap.appendChild(monthLabel)
                currentMonth = j
              }
            }
            //position square
            let x = col * colWidth + gapSize * col + leftMargin
            let y = row * lineHeigth + gapSize * row
            //create square
            let square = document.createElement('div')
            square.classList.add('absolute')
            square.style.width = colWidth + 'px'
            square.style.height = lineHeigth + 'px'
            square.classList.add('rounded')
            let value = this.data[date] ? this.data[date] : 0
            let percent = value / this.maxValue

            let opacity = 'bg-opacity-50'
            square.classList.add('bg-opacity-50')
            let color = 'bg-gray-200'
            if (this.christmasTree) {
              if (percent > 0 && percent <= 0.33) {
                color = 'bg-green-500'
              } else if (percent > 0.33 && percent <= 0.66) {
                color = 'bg-yellow-500'
              } else if (percent > 0.66 && percent <= 1) {
                color = 'bg-red-500'
              } else if (percent > 1) {
                color = 'bg-red-800'
              }
            } else {
              //shades of green
              color = 'bg-gray-200'
              opacity = 'bg-opacity-20'

              if (percent > 0) {
                color = 'bg-green-500'
              }
              if (percent > 0 && percent <= 0.33) {
                opacity = 'bg-opacity-20'
              } else if (percent > 0.33 && percent <= 0.66) {
                opacity = 'bg-opacity-50'
              } else if (percent > 0.66 && percent <= 1) {
                opacity = 'bg-opacity-90'
              }
            }

            if ((row == 5 || row == 6) && !value) {
              color = this.weekendColor
            }

            if (this.customIcons && date in this.customIcons) {
              const icon = document.getElementById(this.customIcons[date]).cloneNode(true)
              icon.removeAttribute('id')
              icon.style.width = colWidth - 2 + 'px'
              icon.style.height = lineHeigth - 2 + 'px'
              icon.classList.add(color)
              icon.classList.add(opacity)
              square.appendChild(icon)
              square.classList.add('flex', 'justify-center', 'items-center')
            }

            square.classList.add(color)
            square.classList.add(opacity)
            square.classList.add('ring-inset')
            if (col > 40) {
              square.classList.add('activity-day-left')
            } else {
              square.classList.add('activity-day')
            }
            let isToday = new Date().toLocaleDateString() == date
            if (isToday && this.showToday) {
              square.classList.add('ring-2')
              square.classList.add('ring-blue-500')
              square.classList.add('ring-opacity-50')
            } else {
              if (this.border) {
                square.classList.add('ring-1')
                square.classList.add('ring-black')
                square.classList.add('ring-opacity-5')
              }
            }

            if (this.customClass && date in this.customClass) {
              const parts = this.customClass[date].split(' ')
              if (parts.length > 1) {
                square.classList.add(...parts)
              } else {
                square.classList.add(this.customClass[date])
              }
            }

            //add data-value
            if (this.data[date]) {
              square.setAttribute('data-value', this.data[date])
            } else {
              square.setAttribute('data-value', 0)
            }
            square.setAttribute('data-label', date)
            square.style.left = x + 'px'
            square.style.top = y + 'px'
            //add square to map
            this.$refs.yearmap.appendChild(square)
            //move to the next line
            row++
            //check if the row is full
            if (row > 6) {
              //move to the next column
              col++
              //reset row
              row = 0
            }
          }
        }
      }
    },
    isDateValid(date) {
      let dateformat = /^(0?[1-9]|[1-2][0-9]|3[01])[/](0?[1-9]|1[0-2])/

      // Matching the date through regular expression
      if (date.match(dateformat)) {
        let operator = date.split('/')

        // Extract the string into month, date and year
        let datepart = []
        if (operator.length > 1) {
          datepart = date.split('/')
        }
        let day = parseInt(datepart[0])
        let month = parseInt(datepart[1])
        let year = parseInt(datepart[2])

        // Create a list of days of a month
        let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
        if (month == 1 || month > 2) {
          if (day > ListofDays[month - 1]) {
            //to check if the date is out of range
            console.debug(date + ': Invalid date')
            return false
          }
        } else if (month == 2) {
          let leapYear = false
          if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true
          if (leapYear == false && day >= 29) {
            console.debug(date + ': Invalid date')
            return false
          } else if (leapYear == true && day > 29) {
            console.debug(date + ': Invalid date format!')
            return false
          }
        }
      } else {
        console.debug(date + ': Invalid date format!')
        return false
      }
      //console.debug("Valid date");
      return true
    }
  }
}
</script>

<style>
.activity-day:hover::after {
  content: attr(data-label) ' - ' attr(data-value);
  position: absolute;
  margin-bottom: -14px;
  right: -73px;
  top: -2px;
  /*background-color: rgba(0, 0, 0, 0.5);*/
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
  text-shadow: 0 0 2px black;
  background-color: black;
  border-radius: 5px;
  padding: 2px 5px;
  z-index: 10;
  pointer-events: none;
}
.activity-day-left:hover::after {
  content: attr(data-label) ' - ' attr(data-value);
  position: absolute;
  margin-bottom: -14px;
  left: -73px;
  top: -2px;
  /*background-color: rgba(0, 0, 0, 0.5);*/
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
  text-shadow: 0 0 2px black;
  background-color: black;
  border-radius: 5px;
  padding: 2px 5px;
  z-index: 10;
  pointer-events: none;
}
.tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
</style>
